<template>
    <div style="height:100%;">
        <img src="../assets/darklogo.png" alt="market forecaster" class="logoImg" />

    </div>
 </template>
 
 <script>
 export default {
   name: 'HeaderComponent',
   props: {
  
   },
   data() {
     return {
     
     };
   },
   methods: {
 
   }
 };
 </script>
 
 <style scoped>
 
 </style>