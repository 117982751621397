<template>
    <div class="w-full h-full" >
        <div class="w-full text-black h-full flex items-center  pr-[1.5rem]" :class="width < 900 ?'space-x-0':'space-x-10 pl-2'">
            <div class="w-fit" :class="width < 900 ?'hidden':''">
            <div v-if="logoName == 'it'">
                <img src="https://www.incometrader.com/assets/img/incometrader-logo-transparent.png" width="80">
            </div>
            <div v-else class="w-[16rem] h-fit">
                <img :src="require(`../assets/${getTheme ? 'MarketForecasterDark.png':'MarketForecasterLight.png'}`)" >
            </div>
            </div>

            <div class="flex items-center justify-between w-full">
            <div @click="symbolModal=true" class="flex items-center space-x-1 hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D] rounded-[4px] scroll-px-32 h-12 cursor-pointer">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 dark:text-[#ffffff]">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                    </svg>
                </span>
                <span class="text-[14px] dark:text-[#ffffff] font-medium">
                    {{ selectedSymbol }}
                </span>
            </div>
            <div @click="handleLogout"  class="p-2.5 cursor-pointer rounded-full hover:bg-[#2d2d2d]">
                <svg fill="#ebebeb" height="15px" width="15px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 384.971 384.971" xml:space="preserve" stroke="#ebebeb"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g id="Sign_Out"> <path d="M180.455,360.91H24.061V24.061h156.394c6.641,0,12.03-5.39,12.03-12.03s-5.39-12.03-12.03-12.03H12.03 C5.39,0.001,0,5.39,0,12.031V372.94c0,6.641,5.39,12.03,12.03,12.03h168.424c6.641,0,12.03-5.39,12.03-12.03 C192.485,366.299,187.095,360.91,180.455,360.91z"></path> <path d="M381.481,184.088l-83.009-84.2c-4.704-4.752-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l62.558,63.46H96.279 c-6.641,0-12.03,5.438-12.03,12.151c0,6.713,5.39,12.151,12.03,12.151h247.74l-62.558,63.46c-4.704,4.752-4.704,12.439,0,17.179 c4.704,4.752,12.319,4.752,17.011,0l82.997-84.2C386.113,196.588,386.161,188.756,381.481,184.088z"></path> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </g> </g></svg>
            </div>

        </div>
        
        <!-- <div class="flex items-center justify-between w-full space-x-2">
            <div class="flex items-center space-x-2">
            <div @click="symbolModal=true" class="flex items-center space-x-1 hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D] rounded-[4px] px-3 h-12 cursor-pointer">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 dark:text-[#ffffff]">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                    </svg>
                </span>
                <span class="text-[14px] dark:text-[#ffffff] font-medium">
                    {{ selectedSymbol }}
                </span>
            </div>
            <div class="w-[1px] h-12 bg-[#d8d8d8] dark:bg-[#545454]" />
            <div ref="timeFrameDropdown" class="relative">

            <div @click="openTimeFrameDropdown = !openTimeFrameDropdown"  class="flex justify-center items-center rounded-[4px] dark:hover:bg-[#2D2D2D] hover:bg-[#F0F3FA] h-12  px-3 cursor-pointer">
                <span class="text-[14px] dark:text-[#ffffff] font-medium">
                  {{ selectedTimeFrame }}
                </span>
            </div>

            <div v-if="openTimeFrameDropdown" class="absolute mt-2 -right-[1rem]" style="z-index: 999;">
                    <SimpleDropdown :options="timeFrameList" @handleSelect="handleTimeFrameChange" ></SimpleDropdown>
                </div>
            </div>

            
            <div class="w-[1px] h-12 bg-[#d8d8d8] dark:bg-[#545454]" />
            <div ref="rangeDropdown" class="relative">
                <div @click="openRangeDropdown = !openRangeDropdown" class="flex  justify-center dark:hover:bg-[#2D2D2D] relative items-center rounded-[4px] hover:bg-[#F0F3FA] h-12  px-3 cursor-pointer">
                    <span class="text-[14px] dark:text-[#ffffff] font-medium">
                        {{ selectedRange?.label }}
                    </span>
                </div>
                <div v-if="openRangeDropdown" class="absolute mt-2 -right-[1rem]" style="z-index: 1000;">
                    <SimpleDropdown :options="rangeList" @handleSelect="handleSelectRange" ></SimpleDropdown>
                </div>
            </div>
            
            <div class="w-[1px] h-12 bg-[#d8d8d8] dark:bg-[#545454]" />
            <div ref="typeDropdown" class="relative">
            <div @click="openTypeDropdown = !openTypeDropdown" class="flex justify-center items-center rounded-[4px] hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D] h-12      px-3 cursor-pointer ">
                <span class="text-[14px] font-semibold w-[2rem]" v-if="getTheme">
                   <img :src="require(`../assets/chart/${selectedChartType?.image}`)" />
                </span>
                <span class="text-[14px] font-semibold w-[2rem]" v-else>
                   <img :src="require(`../assets/chart/light/${selectedChartType?.image}`)" />
                </span>
            </div>
            <div v-if="openTypeDropdown" class="absolute mt-2 -left-[5rem] w-[15rem]" style="z-index: 999;">
                <IconDropdown :options="chartTypes" @handlePress="handleTypeChange" ></IconDropdown>
            </div>
            </div>
            <div class="w-[1px] h-12 bg-[#d8d8d8] dark:bg-[#545454]" />
            <div @click="indicatorModal = true" class="flex items-center space-x-2 hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D] rounded-[4px] px-3 h-12   cursor-pointer">
                <span>
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" class="text-[#181818] dark:text-white" xmlns="http://www.w3.org/2000/svg" stroke="#currentColor"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21 21H6.2C5.07989 21 4.51984 21 4.09202 20.782C3.71569 20.5903 3.40973 20.2843 3.21799 19.908C3 19.4802 3 18.9201 3 17.8V3M7 15L12 9L16 13L21 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                </span>
                <span class="text-[14px] dark:text-[#ffffff] font-medium" :class="width < 900 ? 'hidden':''">
                    Indicators
                </span>
            </div>
            <div class="w-[1px] h-12 bg-[#d8d8d8] dark:bg-[#545454]" />
            </div>
             
            <div ref="mobileSettingDropdown" v-if="width < 900">
                <div @click="openMobileSetting=!openMobileSetting" class="flex relative items-center space-x-2  rounded-[4px] h-12 cursor-pointer">
                    
                <div>    
                <span>
             
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" :class="getTheme?'text-white':'text-black'" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
</svg>


               </span>
                </div>

               <div  v-if="openMobileSetting" :class="getTheme?'dark:bg-[#161616]':'bg-white'" class="rounded-[4px]  px-3 flex flex-col items-center dark:border-none flex-col shadow-lg absolute top-8 mt-2 -right-[0.2rem]" style="z-index: 1000;">
                    <div class=" py-2 hover:bg-gray-100 dark:hover:bg-[#3f3f3f] cursor-pointer">
                    <div @click="handleMagnet" :class="isMagnet?'dark:bg-[#2D2D2D] bg-[#F0F3FA]':''" class="flex items-center space-x-2 hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D] rounded-[4px] px-3 h-12 cursor-pointer">
                        <svg class="-text-[#181818] dark:text-[white] size-6" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="icomoon-ignore"> </g> <path d="M21.345 2.672v14.914c0 2.952-2.393 5.345-5.345 5.345-2.953 0-5.345-2.393-5.345-5.345v-14.914h-6.384v14.928c0 6.478 5.251 11.729 11.729 11.729s11.729-5.251 11.729-11.729v-14.928h-6.384zM26.663 3.738v3.199h-4.251v-3.199h4.251zM9.589 3.738v3.199h-4.251v-3.199h4.251zM16 28.262c-5.88 0-10.662-4.783-10.662-10.662v-9.596h4.251v9.583c0 3.535 2.876 6.411 6.411 6.411s6.411-2.876 6.411-6.411v-9.583h4.251v9.596c0 5.88-4.784 10.663-10.663 10.663z" fill="currentColor"> </path> </g></svg>
                    </div>
                    </div>
                    <div class=" py-2 hover:bg-gray-100 dark:hover:bg-[#3f3f3f]  cursor-pointer">
                        <div @click="handleResistance" title="resistance line" :class="isResistance?'dark:bg-[#2D2D2D] bg-[#F0F3FA]':''" class="flex items-center  hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D] rounded-[4px] px-3 h-12 cursor-pointer" >
                      <svg width="15" height="11" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="2" y1="0.5" x2="11" y2="0.500001" stroke="#3F881C"/>
                      <line y1="6.5" x2="8" y2="6.5" stroke="#B10000"/>
                      </svg>
                    </div>
                    </div>
                    <div class="  py-2 hover:bg-gray-100 dark:hover:bg-[#3f3f3f]  cursor-pointer">
                    <div @click="toggleTheme" class="flex items-center space-x-2 hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D] rounded-[4px] px-3 h-12 cursor-pointer">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6  dark:text-[#ffffff]">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z" />
                                </svg>
                        </span>
                        </div>
                    </div>
                    <div class="  py-2 hover:bg-gray-100 dark:hover:bg-[#3f3f3f]  cursor-pointer">
                        <div @click="handleRefresh" class="flex items-center space-x-2 hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D] rounded-[4px] px-3 h-12 cursor-pointer">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6  dark:text-[#ffffff]">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>
                        </span>
                        </div>
                    </div>
                    <div class="  py-2 hover:bg-gray-100 dark:hover:bg-[#3f3f3f]  cursor-pointer">
                        <div @click="toggleModal = !toggleModal" class="flex items-center space-x-2 hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D] rounded-[4px] px-3 h-12 cursor-pointer">
                        <span >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6  dark:text-[#ffffff]">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                            </svg>
                        </span>
                        
                        </div>
                    </div>
                </div>
               
                </div>
            </div>
            <div v-else class="flex items-center space-x-2">
               
                <div class="w-[1px] h-12 bg-[#d8d8d8] dark:bg-[#545454]" :class="width < 900 ? 'hidden' : ''" />
                <div v-tooltip="'Arrow'"  @click="handleChangeArrow" :class="isArrowOpen ?'dark:bg-[#2D2D2D] bg-[rgb(240,243,250)]':''" class="flex             items-center space-x-2 hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D] rounded-[4px] px-3 h-12 cursor-pointer">
                            <img :src="arrowSrc()" alt="arrowSrc" class="point" />
                        </div>
                    <div class="w-[1px] h-12  bg-[#d8d8d8] dark:bg-[#545454]" />

                    <div @click="handleMagnet" v-tooltip="'Magnet'" :class="isMagnet?'dark:bg-[#2D2D2D] bg-[rgb(240,243,250)]':''" class="flex items-center space-x-2 hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D] rounded-[4px] px-3 h-12 cursor-pointer">
                            <img :src="magnetImgSrc()" class="" />
                        </div>
                    <div class="w-[1px] h-12  bg-[#d8d8d8] dark:bg-[#545454]" />
                <div @click="handleResistance" v-tooltip="'Resistance Line'" :class="isResistance?'dark:bg-[#2D2D2D] bg-[#F0F3FA]':''" class="flex items-center space-x-2 hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D] rounded-[4px] px-3 h-12 cursor-pointer" >
                      

                            <img :src="srImgSrc()" />
                    </div>
            <div class="w-[1px] h-12 bg-[#d8d8d8] dark:bg-[#545454]" />
            <div v-tooltip="'Theme'" @click="toggleTheme" class="flex items-center space-x-2 hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D] rounded-[4px] px-3 h-12 cursor-pointer">
               <span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6  dark:text-[#ffffff]">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z" />
                    </svg>
               </span>
            </div>
            <div class="w-[1px] h-12 bg-[#d8d8d8] dark:bg-[#545454]" />
             <div v-tooltip="'Refresh'" @click="handleRefresh" class="flex items-center space-x-2 hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D] rounded-[4px] px-3 h-12 cursor-pointer">
               <img :src="resetSrc()"  alt="">
            </div>
            <div class="w-[1px] h-12 bg-[#d8d8d8] dark:bg-[#545454]" />
            <div @click="toggleModal = !toggleModal" v-tooltip="'Setting'" class="flex items-center space-x-2 hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D] rounded-[4px] px-3 h-12 cursor-pointer">
               <span >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6  dark:text-[#ffffff]">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>
               </span>
               
            </div>
            <div class="w-[1px] h-12  bg-[#d8d8d8] dark:bg-[#545454]" />

            <div @click="isSettingOpen = !isSettingOpen" v-tooltip="'Setting'" class="flex items-center space-x-2 hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D] rounded-[4px] px-3 h-12 cursor-pointer">
               <span >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 dark:text-white">
  <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
</svg>

               </span>
               
            </div>

            </div>

            
        </div> -->
    </div>
        
        <div v-if="toggleModal">
                <BaseModal>
                     <SettingModal @closeHandler="toggleModal = false"  @onSubmitHandler="onSubmitHandler" :variants="variants" />
                </BaseModal>
        </div>
        <div v-if="symbolModal">
                <BaseModal>
                     <SymbolSearch @closeModal="handleCloseSymbolSearch" @handleSymbol="handleSymbolSelect" />
                </BaseModal>
        </div>
        <div v-if="indicatorModal">
                <BaseModal>
                     <IndicatorModal @close="handleCloseIndicatorModal" @addingIndicator="handleAddingIndicator" />
                </BaseModal>
        </div>

        <div v-if="isSettingOpen">
            <BaseModal>   
                <NewSettingModal  @closeModal="isSettingOpen = false"/>
            </BaseModal>
        </div>

    </div>
</template>

<script>
import Vue from 'vue';
import SimpleDropdown from './dropdowns/SimpleDropdown.vue';
import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';

Vue.use(Tooltip);

import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import BaseModal from './BaseModal.vue';
import SettingModal from './SettingModal.vue';
import NewSettingModal from './NewSettingModal.vue';
import SymbolSearch from './SymbolSearch.vue';
import IndicatorModal from './IndicatorModal.vue';
import IconDropdown from './dropdowns/IconDropdown.vue';

export default {
    name:'NavbarComponent',
    components: {
        SimpleDropdown,
        BaseModal,
        SettingModal,
        SymbolSearch,
        IndicatorModal,
        IconDropdown,
        NewSettingModal
    },
    data() {
        return {
            logoName:'sme',
            rangeList:[
                {key:'1M',label:'1M'},
                {key:'3M',label:'3M'},
                {key:'6M',label:'6M'},
                {key:'YTD',label:'YTD'},
                {key:'1Y',label:'1Y'},
                {key:'5Y',label:'5Y'},
                {key:'All',label:'Max'},
            ],
            timeFrameList:[
                {key:'D',label:'D'},
                {key:'W',label:'W'},
                {key:'M',label:'M'},
             
            ],
            chartTypes: [
                { key: "LineChart", label: "Line Chart",image:'linechart.svg' },
                { key: "Candles", label: "CandleStick Chart",image:'candlestick.svg' },
                // {key: "Area", label: "Area Chart",image:'areachart.svg' },
                {key: "Bars", label: "Bar Chart",image:'barchart.svg' },
                { key: "HeikenAshi", label: "Heiken Ashi Chart",image:'heikenashi.svg' },
                { key: "UpAndDown", label: "Up/Down Chart",image:'updownchart.svg' },
                // { key: "BarTrend", label: "Bar Trend Chart",image:'bartrend.svg' },
                ],
            selectedChartType:{ id: "Candles", name: "CandleStick Chart",image:'candlestick.svg' },
            selectedTimeFrame:'D',
            selectedRange:{key:'1Y',label:'1Y'},
            openRangeDropdown:false,
            openTimeFrameDropdown:false,
            openTypeDropdown:false,
            openMobileSetting:false,
            toggleModal: false,
            symbolModal:false,
            indicatorModal:false,
            selectedSymbol:"IBM",
            width: window.innerWidth,
            refresh: false,
            isArrowOpen: true,
            isSettingOpen: false,
        }
    },
    computed: {
    ...mapGetters(['getTheme']),
   
    },
    props: {
        isMagnet: Boolean,
        isResistance: Boolean,
        variants:Object
    },
    methods: {
        ...mapActions(['toggleTheme','setNewChartType','updateArrow']),

        handleLogout(){
            localStorage.removeItem('auth');
            localStorage.removeItem('token');
            this.$router.push({ name: 'SignIn' });
        },
        handleChangeArrow(){
            this.isArrowOpen = !this.isArrowOpen
            this.updateArrow(this.isArrowOpen)
            console.log("working isArrowOpen",this.isArrowOpen);
        },
        magnetImgSrc(){
        return require(`../../src/assets/${this.isMagnet?'magnet-colored':'magnet-grey'}.svg`);
        },
        srImgSrc(){
        return require(`../../src/assets/${this.isResistance?'s_r-colored':'s_r-grey'}.svg`);
        },
        logo(){
        return require(`../../src/assets/${this.toggleTheme?'darklogo':'logo'}.svg`);
        },
        resetSrc(){
        return require(`../../src/assets/${this.refresh?'reset-green':'reset-grey'}.svg`);
        },
        arrowSrc(){
        return require(`../../src/assets/${this.isArrowOpen?'arrows-colored':'arrows-grey'}.svg`);
        },
        handleAddingIndicator(item){
        console.log("Debug item==>",item);
           this.$emit("addIndicator",item)
        },
        handleMagnet(){
            this.$emit("onMagnetPress")
        },
        onSubmitHandler(payload){
         this.$emit("onSubHandler", payload)
        },
        handleTypeChange(t){
            this.selectedChartType = t
            this.openTypeDropdown = false
            this.setNewChartType(t.key)
        },
        handleCloseIndicatorModal(){
            this.indicatorModal = false
        },
        handleSymbolSelect(s){
           this.handleSelectRange(this.selectedRange)
           this.selectedSymbol = s
           this.$emit("getSymbolData", s) 
        },
        handleCloseSymbolSearch(){
            this.symbolModal = false
        },
        handleSelectRange(r){
           this.selectedRange = r
           this.openRangeDropdown = false;
           this.$emit("getTimeStamp", r.key)
        },
        handleTimeFrameChange(t){
            this.selectedTimeFrame = t.key
            this.$emit("timeFrameEmit", t.key)
            this.openTimeFrameDropdown = false
        },
        handleRefresh(){
            this.refresh = true
            this.$emit("refresh")
            setTimeout(()=>{
                this.refresh = false
            },1000)
        },
        handleResistance(){
            this.$emit("onPressResistance")
        },
        // handleClickOutside(event) {
        //     if (!this.$refs.rangeDropdown.contains(event.target)) {
        //         this.openRangeDropdown = false;
        //     }
        //     if (!this.$refs.timeFrameDropdown.contains(event.target)) {
        //         this.openTimeFrameDropdown = false;
        //     }
        //     if (!this.$refs.typeDropdown.contains(event.target)) {
        //         this.openTypeDropdown = false;
        //     }
        //     if(this.width < 900){
        //         if (!this.$refs.mobileSettingDropdown.contains(event.target)) {
        //             this.openMobileSetting = false;
        //         }
        //     }
        //     },
        onResize() {
            this.width = window.innerWidth
        },
    },
    mounted() {
    document.addEventListener('click', this.handleClickOutside);
    const queryParams = new URLSearchParams(window.location.search);
    window.addEventListener("resize", this.onResize);

    this.logoName = queryParams.get("watermark") == 'it' ? 'it' : 'sme'

    let x = localStorage.getItem('symbolName')
    if(x){
      this.selectedSymbol = x
    }else{
        this.selectedSymbol = "IBM"
    }

    },
    beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
}
</script>
<style scoped>
.point{
    cursor: pointer;
}
</style>