<template>
    <div class="sm:w-[45vw] h-[80vh]">
        <div class="flex w-full h-full text-[#000000] p-4">
            <div class="w-full">
                <div class="flex justify-between  items-center">
       <p class="font-[500] dark:text-[#F6F6F6] text-[24px]">     
                Search Symbols
                </p>
                <!-- <div
            class="p-2 rounded-full  block bg-[#f0f3f5] cursor-pointer hover:bg-[#e4e3e3] dark:hover:bg-[#232323] dark:bg-[#FFFFFF1A]"
              @click="closeModal"
            >
              <div class="dark:text-[#B0B0B0]">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 4L4 12"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4 4L12 12"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div> -->
            </div>
  <!-- Search Bar -->
    <div class="flex items-center w-full space-x-1 mt-2 dark:hover:bg-[#23232370] px-2 py-1.5 cursor-pointer  text-sm hover:bg-[#eaeaea] dark:bg-[#121212] bg-[#F6F6F6] rounded-xl" :class="getTheme ? '!border-[#1a1a1a] border' :'border border-[#D1D1D1]'">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z" stroke="#6D6D6D" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M17.5 17.5L13.875 13.875" stroke="#6D6D6D" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  
      <input v-model="inputValue"  class="bg-transparent text-[#181818] uppercase dark:text-[#ffffff80] placeholder:text-[#6D6D6D] placeholder:dark:text-[#6D6D6D] placeholder:text-[14px] text-[16px] focus:outline-none w-full " placeholder="Symbol, eg: AAPL" />
    <div class="flex items-center space-x-3">
        <p @click="clearInput" class="text-[#7C7C7C] text-[14px]">
        Clear
    </p>
    <svg width="2" class="text-[#E7E7E7] dark:text-[#7C7C7C]" height="28" viewBox="0 0 2 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="2" height="28" fill="currentColor"/>
  </svg>
  
        <svg @click="closeModal" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18 6L6 18" stroke="#7C7C7C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6 6L18 18" stroke="#7C7C7C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  
    </div>
    
    </div>

    <!-- suggestion  -->
    <div class='w-full flex items-center my-4 space-x-3 px-2'>
        <div v-for="(item,i) in suggestion" :key="i" className='flex items-center text-[14px] suggestionText  font-[400]' @click="handleData(item)">
            <div class="flex p-1.5 w-fit px-4 rounded-3xl" :class="selectedVal.key == item.key ? 'text-[#F6F6F6] cursor-default bg-[#065036] font-[500]':'bg-[#E2E8F0] cursor-pointer hover:bg-[#ced8e5]  dark:bg-[#1a1a1a] dark:text-[#ffffff60] text-[#292929]'">
                {{ item.value }}
            </div>
        </div>
    </div>

  <!-- Rows -->
  <div class="w-full h-full">
    <div class="w-full h-[60%] flex items-center justify-center text-[16px] text-[#292929] dark:text-[#F6F6F6] font-[500]" v-if="isLoading">
        <Loader></Loader>
    </div>

    <div class="w-full h-full sm:text-[16px]  text-[12px] overflow-y-auto no-scrollbar oveflow-x-hidden pb-[10rem]"  v-else-if="tickerInfo?.length > 0">   
    <div v-for="(items,key) in tickerInfo" :key="key" @click="selectSymbol(items)" class="flex items-start justify-between cursor-pointer px-2 hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D70] border-b dark:border-b-[#141414] py-4 border-b-[#E7E7E7]">
    <div class="flex items-start sm:space-x-4 space-x-1">    
  <!-- <img :src="require(`../../assets/images/${items.img}`)" :class="screenWidth < 521 ? 'w-6 h-6' : ''" /> -->
        <div>
            <div class="w-12 h-12 flex uppercase items-center dark:bg-[#1a1a1a] dark:text-[#ffffff60] justify-center rounded-full bg-[#E2E8F0]">
                {{items?.key?.symbol[0]}}
            </div>
            <!-- <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <rect width="44" height="44" rx="22" fill="url(#pattern0_2958_265037)"/>
            <defs>
            <pattern id="pattern0_2958_265037" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlink:href="#image0_2958_265037" transform="scale(0.0178571)"/>
            </pattern>
            <image id="image0_2958_265037" width="56" height="56" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAADsUlEQVRoBe2Z+0sUURTH/Ve0QJMooZQQobDMyhLRJK20kn7qB6mQMi0zKOiXHpoZvSkKgijFX6KIMs1HRGaR9vopldx1H7m6mzuzrt84Y97WcS7O6t27ZXPhwtlzzpz7/dx7Z/bubIwtNh4LuccsZDhiswD/9RW2VtBawb/8KWxtUWuLWls0uicl6x607sH/6R60J6XAvb0YnvJKeOsuYayxCeqr1wh8+Yqg3Q4oitbJJh/FKMd7oR6ewxXatfZlK4X+upnXPejaWghv/WUoLa0IOhwQ1WgClOYWDdyVkz8v4LABnZmbNajgwMBMHr8f6psujD1owOjZGgzvLwNNgmPdRgwlp8K2aInWySafK78QwwfKMHquFmMPG6F2vZ1cZV3l8W992o5wZGwKGzZsQAJgTVE1YZ6ycji35MJdWAxP5XH4rt2E8rIdE6NerTvWbpghjAAnvJNxyvVdvQFPRRVc23bAmZ0Lz6EKjDU0AarKhqOxw33ohQ3o3r0XgQ898BythntXCbw1dVA6OgHljxCm6Lcx3j8A+/JkJo5s8nGbokBpbcPomfNwFezESNUJBD59hruohNUwCxo2IBV2ZudB7X7P1WcUUNo62BZV2juNUrg+ehg5s3LChiOtcwIcSklDcNDGFcQL+G7dge/2XV6Y69d2wIpV8gBpZhxrMhDo/chE0aN+KHX1NBGeI8dYnGdQTuh2oxq+K9dZutr9Do609Gk5ofmz2XNaQVY0filGqk/C/+ixoQBXXgETyjMoh9ULOTQoz19o36e2xYmGcaNrjHzzAwwRZFTcnpjE42J+yjG6VpQvooAkcryvn8HoDYqJAuHViTig/8lTPRf7TDGeMFH+iAN6ay8yIL1BMVEgvDoRB/yxr1TPxT5TjCdMlD/igI70TAakNygmCoRXJ+KANLDhMU5RIw5HY0sBNDrWkY836yL9UgB/3ruv350gn0gQXi0pgHTa0Tfy8USJ9EsBdBcU6flAPpEgvFpSAOn3n76F/j7kiRPhlwJIQoPfBxkj2SLEm6khDdD/rJkBkm1GnIgcaYD09m2qkS1CvJka0gCHSw9O8YFsM+JE5EgDdK7PYoBkixBvpoY0QFtcwuSRjY5ocQkLEDA2HoGeXq2bmXlROfJWMDZee+NNb71FiTdTRyrgyKnToG5GmKgcqYDuoj2gLkq8mTpSAelPF+1PmFnexpkRbjZHKqBZUSLzLECRsxmNWtYKRmPWRY5praDI2YxGLWsFozHrIsdc8Cv4C5VQoHFdYZADAAAAAElFTkSuQmCC"/>
            </defs>
            </svg> -->

        </div>
  <div class="dark:text-[#F6F6F6]">
  <p class="font-[300] text-[1.3rem]" :title="items?.equityinfo?.longname"><span class="font-[600] mr-2" :class="screenWidth < 361 ? 'text-[12px]' : ''" >{{ items?.key?.symbol }}</span><span class="!font-normal dark:text-[#ffffff90]">{{ getTruncateText(items?.equityinfo?.longname) }}</span></p>
  <p class="text-[#BDBDBD] sm:text-[14px]">{{ items?.key?.exchange}}</p>
  </div>
 
  </div>
  
  <div  class="flex sm:items-center sm:space-x-5">
    <div>
        <!-- <p class="font-[600] dark:text-white priceText">${{ items.price }}<span class="text-[12px] changeText ml-1  text-[#037950]">{{ items.change }} ({{  items.change_percent}})</span></p> -->
        <p class="font-[600] dark:text-white priceText">{{item?.eodHistoryData?.close ? `$ ${item?.eodHistoryData?.close}` : '$0' }}<span class="text-[12px] changeText ml-1  text-[#037950]">{{ item?.eodHistoryData?.change || '0' }} {{item?.eodHistoryData?.changepercent ? (`${item?.eodHistoryData?.changepercent}%`) : '(0.0%)'}}</span></p>
        <div>
            <p class="text-[#BDBDBD] sm:text-right font-[600] text-[9px]" :class="screenWidth < 361 ? 'text-[7px]' : ''">Last updated: Yesterday</p>
        </div>
    </div> 
   
  </div>
  </div>
  </div>
  <div class="w-full h-[80%] flex items-center justify-center text-[16px] text-[#292929] dark:text-[#F6F6F6] font-[500]" v-else>
    <p>No Symbol Found</p>
    
</div>
  
  </div>
  
  </div>
  
  </div>
    </div>
  </template>

<script>
import { mapGetters } from 'vuex';
import postRequest from '../api/postRequest'
import {serverUrl} from '../api/serverUrl'
import Loader from '../component/Loading.vue'

export default {
name:"searchSymbol",


data(){

return{
  inputValue:"",
  isLoading:false,
  header:[
    {id:1,name:"All",value:"all"},
    {id:2,name:"Recently Searched",value:"recentlySearched"},
    {id:3,name:"Frequently Searched",value:"frequentlySearched"},
    {id:4,name:"Good Stocks",value:"goodStocks"},
    {id:5,name:"{Scanner}",value:"scanner"},
    {id:6,name:"{Scanner}",value:"scanner1"},
    {id:7,name:"{Scanner}",value:"scanner2"},
    {id:8,name:"{Scanner}",value:"scanner3"},
  ],
  
  suggestion:[{key:"all",value:"All"},{key:"recentlySearched",value:"Recently Searched"},{key:"frequentlySearched",value:"Frequently Searched"}],
  selectedVal:{key:'all',value:"All"},
  screenWidth: window.innerWidth,
  tickerInfo:[],

}

},

components:{
    Loader  
},

computed:{
    ...mapGetters(['getTheme']),
filteredContent() {
      return this.tickerInfo?.filter((item) => item?.ticker?.toLowerCase().includes(this.inputValue?.toLowerCase()));
    }
},
methods:{
    async handleSymbolData(){
        this.isLoading = true
        let payload = {
                sortBy: null,
                order: "asc",
                page: 1,
                itemsPerPage: 10,
                searchTerm: {
                    Symbolstring: {
                        value:this.inputValue?.toUpperCase(),
                        expression: "startsWith"
                    }
                }
            }
        
            try{
                let res = await postRequest(serverUrl+'/api/Admin/getDataSet',payload)
                if(res?.data?.length > 0){
                    console.log("res",res?.data)
                    this.tickerInfo = res?.data
                }else{
                    this.tickerInfo = []
                }
            }catch(err){
                console.log(err)
            }
            this.isLoading = false
    },
    selectSymbol(item){
        console.log("item",item)
        this.$emit("handleSymbol",item?.key?.symbol)
        this.closeModal()
    },  
handleKeyDown(event) {
    if (event.keyCode === 27) {
      this.closeModal()
    }
  },
clearInput(){
  this.inputValue=""
},
getTruncateText(val){
  let maxLength = 15
  if(this.screenWidth > 500){ 
    if (val?.length > maxLength && this.screenWidth < 1151) {
      return val?.substring(0, maxLength) + "..";
    }else{
        if(val?.length > 30){
            return val?.substring(0, 30) + "..";
        }else{
            return val
        }
    }
  }



},
  closeModal() {
    this.$emit("closeModal");
  },
  handleData(item){
      this.selectedVal=item
  },
  updateScreenWidth(){
          this.screenWidth = window.innerWidth;
    },
},

watch:{
    inputValue(val){
        if(val){
            this.handleSymbolData()
        }else{
            this.tickerInfo = []
        }
    }
},

mounted() {
  // Add event listener when the component is mounted
  window.addEventListener('keydown', this.handleKeyDown);
  window.addEventListener('resize',this.updateScreenWidth)

},
beforeDestroy() {
  // Remove event listener when the component is destroyed to avoid memory leaks
  window.removeEventListener('keydown', this.handleKeyDown);
  window.addEventListener('resize',this.updateScreenWidth)
},

}
</script>