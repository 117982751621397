<!-- BaseModal.vue -->

<template>
    <div class="md:w-[45rem] md:h-[50rem] xl:w-[45rem] xl:h-[50rem] w-[25rem] h-[30rem] flex flex-col">
        <!-- top element -->
        <div class="flex justify-between items-center md:py-4 py-2 border-b-[2px] dark:border-b-[#141414]">
            <span class="text-[16px] font-semibold pl-6 dark:text-[#F6F6F6]  text-[#363739]">Chart settings</span>
            <span class="text-[20px]  p-2 hover:bg-gray-200 rounded-full cursor-pointer mr-8" @click="$emit('closeHandler')">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
            </span>
        </div> 
        <!-- center element -->
        <div class="flex flex-1 ">
            <div  class="flex space-y-0.5 flex-col w-[30%] h-full border-r dark:border-r-[#141414]">
             
               <span @click="onClickeHandler(item)" v-for="item in allSettings" :key="item.id" :class="isSelected.value === item.value ? 'bg-gray-200 dark:bg-[#212020] ' : 'hover:bg-gray-100' " class=" px-4 py-3 md:text-[14px] text-[12px] font-light cursor-pointer dark:hover:bg-[#2D2D2D] dark:active:bg-[#212020] dark:text-white">{{ item.name }}</span>
               
            </div>
            <div class="flex w-[70%] h-full ">
                <div class="w-full">
                    <div v-for="(item) in isSelected.chartSettings" :key="item.id" class="flex items-center justify-between py-2.5 md:py-3 px-4 border-b dark:border-b-[#141414]">
                        <label :for="'input-' + item.id" class="text-[12px] md:text-[14px] flex flex-row items-center justify-between w-full dark:text-white cursor-pointer" :class="(getSelectedVariant && getSelectedVariant?.firstVariant) && item.name == 'candleBorder'?'pointer-events-none opacity-50':''">
                            {{ item.value }}
                            <input @input="updateSetting(item)" v-if="item.type === 'color'" :id="'input-' + item.id" type="color" class="color-picker p-2 md:w-10 md:h-10 w-8 h-8 cursor-pointer dark:bg-[#2d2d2d] shadow-xl rounded-[4px] hover:opacity-70" placeholder="Color" v-model="item.defaultValue">
                            <input @input="updateSetting(item)" v-if="item.type === 'checkbox'"  :id="'input-' + item.id" type="checkbox" class="green-checkbox  border-none "  placeholder="Checkbox"  v-model="item.defaultValue" >
                            <input @input="updateSetting(item)"   v-if="item.type === 'radio'" :id="'input-' + item.id" :value="item.value" :checked="variants[item.key]" :name="item.name"  type="radio" class="border-none" placeholder="radio">
                        </label>
                       
                    </div>
                </div>
            </div>
        </div>
        <!-- bottom element -->
        <div class="flex justify-end items-center px-4 py-2 md:py-3 border-t dark:border-t-[#141414]"> 
           
            <div class="flex gap-2 ">
                <button class="py-2 px-6 md:py-2 md:px-8 hover:bg-[#0e7a54] bg-[#139467] text-white rounded-[6px]" @click="onSubmitHandler">OK</button>
                <button class="py-1 px-6 md:py-2 md:px-8 hover:bg-[#313333] bg-[#414343] text-white rounded-[6px]" @click="onCloseHandler">Cancel</button>
            </div>
        </div>
    </div>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex';
import {isSelected,allSetting,variantColors} from "../utils/chartSetting"


export default {
    data(){
        return {
            selectedValue: this.getSelectedRadio(),
            settingArray: [],
            isSelected:  isSelected,
            allSettings: allSetting,
            isDisableBorder:false,
            currentRadioSelected:{firstVariant:false,secondVariant:false,thirdVariant:false,fourthVariant:false,fifthVariant:false}
           
        }
    },
    methods:{
     
        ...mapActions(['selectedVariant','updateSettings']),
      
        onClickeHandler(item){
            this.isSelected = item;
         
        },

        updateArray(arr, obj) {
                    for (let category of arr) {
                        if (category?.name === 'General') {
                        for (let item of category?.chartSettings) {
                            if (item?.name === obj?.name) {
                                item.defaultValue = typeof obj.defaultValue == "boolean" ? !obj.defaultValue : obj.defaultValue;
                                console.log("item==>", item.defaultValue,obj.defaultValue);
                            }
                        }
                        }
                    }
                    return arr;
                    },


        updateSetting(item) {
            if(item.type === "radio"){
                this.selectedVariant( item.variantList)
                const updates = variantColors[item?.key]

                this.allSettings?.forEach(setting => {
                    if (setting.name === "General") {
                        setting?.chartSettings.forEach(chartSetting => {
                            if (updates.hasOwnProperty(chartSetting.name)) {
                                chartSetting.defaultValue = updates[chartSetting.name];
                            }
                        });
                    }
                });

                if(this.getSelectedVariant?.firstVariant){
                  
                    this.isDisableBorder = true
                }else{
                    this.isDisableBorder = false
                }
                
            }else{   
                let updatedArr = this.updateArray(this.allSettings, item);
                this.allSettings = updatedArr
            }

        },
        onSubmitHandler(){
            let mergeSettingWithVariant = this.allSettings[0]?.chartSettings
            if(mergeSettingWithVariant?.length > 0){
                this.updateSettings(this.allSettings)
                this.$emit("onSubmitHandler",mergeSettingWithVariant);
                this.$emit('closeHandler')
            }
        },
        onCloseHandler(){
            this.$emit('closeHandler');
        },
        getSelectedRadio() {
            const selectedItem = this.settingArray?.find(item => item?.type === 'radio' && item?.defaultValue);
            return selectedItem ? selectedItem.value : '';
        },
    },
    computed:{
        ...mapGetters(['getSelectedVariant','getTheme','getUpdatedSettings']),
        getRadioCheck(val){
            return true
        }
 
    },
    props: {
        variants:Object
    },
    mounted(){
        if(this.getUpdatedSettings?.length > 0){
            this.allSettings = this.getUpdatedSettings
            this.isSelected = this.getUpdatedSettings[0]
        }     
    },
    watch:{
        getSelectedVariant(val){
            this.currentRadioSelected = val
            console.log("val===>",val)
        }
    }
}
</script>

<style scoped>
.green-checkbox {
    margin-right: 5px;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid gray;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    border-radius: 4px;
    cursor: pointer;
    position: relative;
}

/* Style for the checked state */
.green-checkbox:checked {
    background-color: green;
    border-color: green;
}

/* Optional: Style for the checkmark */
.green-checkbox:checked::after {
    content: "";
    position: absolute;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* input[type="radio"] {
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;
  width: 14px;
  height: 14px;
  border: 2px solid #adadad; 
  border-radius: 50%; 
  outline: none;
  cursor: pointer;
  padding:5px
}


input[type="radio"]:checked {
  background-color: green; 
} */

input[type="radio"] {
	appearance: none;
	-webkit-appearance: none;
	width: 20px;
	height: 20px;
	border: 1px solid #949494;
	border-radius: 50%;
	margin-right: 10px;
	background-color: transparent;
	position: relative;
	top: 6px;
}

@media screen and (max-width: 450px){
    input[type="radio"]{
        width: 10px;
        height: 10px;
        top:2px
    }
}
input[type="radio"]:checked::before {
	content: "";
	display: block;
	width: 12px;
	height: 12px;
	background-color: #2d9500;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	animation: appear 0.3s;
}



@keyframes appear {
	0% {
		transform: translate(-50%, -50%) scale(0);
		background-color: #2d9500;
	}
	45% {
		transform: translate(-50%, -50%) scale(1.6);
		background-color: #2d9500;
	}
	50% {
		transform: translate(-50%, -50%) scale(1.7);
		background-color: #2d9500;
	}
	55% {
		transform: translate(-50%, -50%) scale(1.6);
	}
	100% {
		transform: translate(-50%, -50%) scale(1);
		background-color: #2d9500;
	}
}

</style>
