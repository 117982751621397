import axios from 'axios'
import logger from './logging'
import alerts from './alerts'
import Config from "../config";
// 'https://users.lakshanperera.dev'
//process.env.USER_API_ENDPOINT
// const baseUrlForUserApi =  "http://localhost:4000"  //"http://localhost:3000"  

// const baseUrlForUserApi = "http://192.168.0.103:3000"                
const baseUrlForUserApi = Config.USER_API                
// const baseUrlForUserApi = "http://192.168.0.101:8080"                
const userHttp = axios.create({ baseURL:baseUrlForUserApi   })


userHttp.interceptors.response.use(null, (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    if (axios.isCancel(error)) {
      return error.message
    }
  
    const message =
      error.response && error.response.data && error.response.data.error
    if (message) {
      error.message = message
    }
    if (!expectedError) {
      logger.log(error)
      alerts.showError(error)
    }
  
    return Promise.reject(error)
  })

export const createAccount = async(payload)=>{
    try {
      let res = await userHttp.post('/auth/register',payload)
      if(res){
        return res
      }else{
        return false
      }
      
    } catch (e) {
      return false
    }
  
  }

  export const signin = async(payload)=>{
    try {
      let res = await userHttp.post('/auth/login-new',payload)
      if(res){
        return res
      }else{
        return false
      }
      
    } catch (e) {
      return false
    }
  
  }