<template>
    <div class="bg-[#181818]" style="height:100%;width:100%;border-radius: 30px;padding:30px;display: flex;flex-direction: column;justify-content: space-between;overflow-y: auto;overflow-x: hidden;">
              <!-- <div style="height:5%;font-size:12px;width:100%">
    
      <div style="display: flex; align-items: center;justify-content: center;">
      <div style="width: 90%;display: flex;justify-content: space-between;">
          <div style="display: flex;width:fit-content; margin-right: 2rem;" v-for="(item,i) of marqueData" :key="i">
                  <span style="color:#828282;width: 80%;white-space: nowrap;">{{ item?.name }}</span> 
                  <span style="color:#0FFE50;padding-left: 2rem;">{{ item?.percent }}</span> 
              </div>
      </div>
      </div>



              </div> -->
              <div style="height:100%;display: flex;flex-direction: column;align-items: center;padding-top: 20px;">
                  <div style="height:70%;width: 90%;" class="trial-info-header">
                      <!-- trial content -->
                      <div style="border:1px solid #0FFE50;font-size: 18px;color:#0FFE50;width: fit-content;border-radius: 5px;padding: 5px 15px 5px 15px;font-weight: 500;">
                          14-DAYS FREE TRIAL
                      </div>
                      <div class="trial-Heading" style="margin: 6% 0% 2% 0%;font-size: 65px;font-weight: bold;">
                          <span style="color:white">Instantly Analyze 1,000+  </span> 
                          <span style="color:#0FFE50">Stocks  </span>
                          <span style="color:white">with Market Forecaster</span>
                      </div>
                      <div class="join-us-container">
                          <div class="user-profile">
                              <img src="https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="User 1">
                          </div>
                          <div class="user-profile">
                              <img src="https://images.unsplash.com/photo-1556157382-97eda2d62296?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="User 2">
                          </div>
                          <div class="user-profile">
                              <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&q=80&w=1974&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="User 3">
                          </div>
                          <div class="user-profile">
                              <img src="https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?auto=format&fit=crop&q=80&w=1976&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="User 4">
                          </div>
                          <div class="join-us-text text-white">5k+ Stock Traders joined us, now it's your turn</div>
                      </div>

                  </div>
                  <div style="height:30%;width: 90%;color:#AEAEAE;font-size: 15px;text-align: justify;">
                      <!-- important notice -->
                      <span style="font-weight:bold;" class="text-white">IMPORTANT NOTICE: </span>
                      <span style="font-weight:200;color:#e2e2e2;" class="disclaimer-text">
                        MarketForecaster's website is for general information only and is not intended to address any individual's personal financial requirements, goals, objectives, or life situation. The information provided does not constitute personal, tailored, or one-to-one advice and should not be considered a recommendation regarding your investment decisions. MarketForecaster is not a financial advisor and does not present itself as such.
                            This website is not a substitute for professional financial advice and does not account for your personal circumstances. The content of this site changes daily, and past performance results do not guarantee future outcomes. The information on this website is intended for the general public and is not aimed at any specific individual.
                        We encourage you to seek advice from a trusted and qualified financial advisor. Nothing on this website should be interpreted as a solicitation to buy or sell any financial product. Any reliance placed on the information on this website is strictly at your own risk, and you accept full responsibility for your investment decisions. Investing in stocks involves the risk of financial loss when stock prices decline. Only trade with funds you can afford to lose.
                      </span>
                  </div>
              </div>
          </div>
</template>

<script>
// import DynamicMarquee from 'vue-dynamic-marquee'



export default {
 name: 'TrialInfo',
 components: {
    //   DynamicMarquee
  },
 props: {

 },
 data() {
   return {
      marqueData: [
              {name:"Apple Inc",percent:"+0.37%"},
              // {name:"E. I. du Pont de Nemours and Company",percent:"+0.08%"},
              // {name:"Alcoa Corporation",percent:"+1.31%"},
              {name:"Adicet Bio Inc",percent:"-0.14%"},
              {name:"Adagene Inc",percent:"+0.42%"},
              {name:"Addus HomeCare Corp",percent:"+0.99% "},
          ],
   };
 },
 methods: {

 }
};
</script>

<style scoped>

</style>