// store/index.js
import Vue from 'vue';
import Vuex from 'vuex';
import Bars from "../overlays/chartTypes/Bars.vue";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    variants: {firstVariant:false,secondVariant:false,thirdVariant:false,fourthVariant:false,fifthVariant:false,sixthVariant:true},
    tradingChartReference: null,
    theme: true,
    isArrowOpen: false,
    selectedIndicator:[
      {
        id: 1,
        name: "SMAX",
        desc: "Simple Moving Average",
        type:'Spline',
        label:{},
        settings:{},
        chartType:'onchart',
        data:[]
      },
      {
        id: 7,
        name: "MACD",
        desc: "Moving Average Convergence/Divergence",
        chartType:'offchart',
        type:'MACD',
        data:[],
        settings: {
          macdColor: '#3782f2',
          signalColor: '#f48709',
          histColor: '#C0C0C0',
            histWidth: 4,
            macdWidth: 1,
            signalWidth: 1,
            crosses: [],
          },
      },
      {
        id: 8,
        name: "RSI",
        chartType:'offchart',
        desc: "Relative Strength Index",
    },
 

    ],
    chartType: 'Candles',
    barTrendChart: 34,
    selectedSymbol: 'TSLA',
    removeIndicatorName:null,
    currentSymbolData:[],
    updatedSettings: [],
    currentChartSetting:null
  },
  mutations: {
    UPDATE_TCI_PARAMS(state,payload) {
      state.barTrendChart = payload
  },
    UPDATE_SYMBOL(state, payload) {
      state.selectedSymbol = payload
    },
    UPDATE_CHART_SETTING(state, payload) {
      state.currentChartSetting = payload
    },
    SET_TRADING_CHART_REFERENCE(state, payload) {
      state.tradingChartReference = payload.tradingChartReference
     },
    setVariant(state,variants){
      state.variants = variants;
    },
    setTheme(state, theme) {
      state.theme = theme;
    },
    ADD_INDICATOR(state, indicator) {
      state.selectedIndicator.push(indicator);
    },
    UPDATE_INDICATOR_LIST(state, indicator) {
      state.selectedIndicator = indicator;
    },
    REMOVE_INDICATOR(state, indicator) {
      state.selectedIndicator = state.selectedIndicator.filter(ind => ind.name !== indicator.name);
    },
    SET_CHART_TYPE(state, payload) {
      state.chartType = payload
  },
  UPDATE_SYMBOL_DATA(state,payload){
    state.currentSymbolData = payload
  },
  UPDATE_REMOVE_INDICATOR(state,payload){
    state.removeIndicatorName = payload
  },
  UPDATE_SETTINGS(state,payload){
    state.updatedSettings = payload
  },
  UPDATE_ARROW(state,payload){
    state.isArrowOpen = payload
  }
},
  actions: {
    updateArrow(context,payload){
      context.commit('UPDATE_ARROW',payload)
    },
    updateSymbolName(context,payload){
      context.commit('UPDATE_SYMBOL', payload)
    },
    updateChartSetting(context,payload){
      context.commit('UPDATE_CHART_SETTING', payload)
    },
    updateSettings(context,payload){
      context.commit('UPDATE_SETTINGS', payload)
    },
    updateOhlcData(context, payload) {
      context.commit('UPDATE_SYMBOL_DATA', payload)
    },
    updateRemoveIndicatorName(context, payload) {
      context.commit('UPDATE_REMOVE_INDICATOR', payload)
    },
    goToDateChart(context,timeStamp){
      console.log("store",timeStamp)
      context.state.tradingChartReference.goto(timeStamp)
  },
    selectedVariant({commit},payload){
      commit('setVariant', payload)
    },
    toggleTheme({ commit, state }) {
      const newTheme = state.theme === false ? true : false;
      commit('setTheme', newTheme);
    },
    updateIndicatorList({ commit, state }, indicator){
      commit('UPDATE_INDICATOR_LIST', indicator);
    },
    toggleIndicator({ commit, state }, indicator) {
      const exists = state.selectedIndicator?.find(ind => ind?.name === indicator?.name);
      if (exists) {
        console.log("remove call")
        commit('REMOVE_INDICATOR', indicator);
      } else {
        console.log("add call")
        commit('ADD_INDICATOR', indicator);
      }
    },
    async setNewChartType(context, payload) {
      context.commit('SET_CHART_TYPE', payload)
      return true
    
  },
  },
  getters: {
    getTheme: state => state.theme,
    getSelectedIndicators:state => state.selectedIndicator,
    getChartType: state => state.chartType,
    getSelectedVariant: state => state.variants,
    tv: state => state.tradingChartReference,
    dc: state => state.tradingChartReference?.data,
    barTrendChart: state => state.barTrendChart,
    getSymbolData: state => state.currentSymbolData,
    symbolName:state=>state.selectedSymbol,
    getRemoveIndicatorName:state=>state.removeIndicatorName,
    getUpdatedSettings:state=>state.updatedSettings,
    getCurrentChartSetting:state => state.currentChartSetting,
    getArrow: state => state.isArrowOpen
  },
});
