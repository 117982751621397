<template>
    <div  style="width:100%;height:100%;overflow: hidden;padding: 40px 40px 40px 80px;" class="page-background mobileMainContainer">
        <div v-if="showResendEmail" style="width:100%;height:100%;position: relative;">   
            <div style="height:10%">
                <Header></Header>
            </div>
            <div style="height:80%;">
                <!-- @resendEmail="handleResendEmail"  -->
                <ResendEmail :isResendLoading="isResendLoading" :isResponseMessage="isResponseMessage"></ResendEmail>
            </div>

            <div class="smallFooter" style="display: none">
                <SmallFooter></SmallFooter>
            </div>
            
            <div class="hideDetail" style="height:5%">
                <Footer></Footer>
            </div>
        </div>
        
        <div v-else class="pageContainerParent"  style="width:100%;height:100%;display:flex; justify-content: space-between">
            <div class="pageContainer"  style="height:100%;width:25%;display: flex; flex-direction: column;justify-content: space-between;">
                <div style="height:8%" class="logoArea">
                    <Header></Header>
                </div>
    
                <div v-if="true" style="height:80%;" >
                    <!-- form -->
                    <div style="display: flex;align-items: center;font-size: 15px;" class="errorText haveAnAcc">
                        <span style="color:white" class="text-[15px]" >Already have an account?</span>
                        <router-link :to="{name: 'SignIn'}">    
                        <span style="display:flex;align-items: center;">
                        <span style="color:#0FFE50;margin: 0px 5px 0px 5px;cursor: pointer;" class="hoverUnderline text-[15px]">
                             Sign In
                        </span>
                        <img src="../assets/login.svg" />
                        </span>
                    </router-link>
                    </div>

                    <div class="formFreeTrial mb-6" style="height:10%;display: flex;flex-direction: column;justify-content: center;font-size: 40px;color:#300003;font-weight: bold;">
                        <div class="formSevenDayTrial" style="border:1px solid #F20E17;font-size: 18px;color:#F20E17;width: fit-content;border-radius: 5px;padding: 5px 15px 5px 15px;font-weight: 500;display: none;margin-bottom: 10px;">
                            14-DAYS FREE TRIAL
                        </div>
                        <span class="headerTitle text-white">
                            Create an Account
                        </span>
                        <span v-if="showResponseError" class="errorText" style="color:#F20E17;font-size: 15px;margin-top: -2%;font-weight: 300;">{{ showResponseError }}</span>
                    </div>
                    <div class="inputHeight" style="height: 8%;">
                        <DynamicInput type="text" placeholder="Name" :isError="isUserError" :iconImg="require(`@/assets/${isUserError ? 'redUser.svg':'user.svg'}`)" v-model="username"/>
                    </div>        
                    <div style="height:4%;margin-top: 1%;">
                        <span class="errorText"  style="color:#F20E17;font-size:13px">{{ userErrorMsg }}</span>
                    </div>
                    <div class="inputHeight" style="height: 8%;">
                        <DynamicInput type="number" maxLength="15" placeholder="Phone" :isError="isPhoneError" :iconImg="require(`@/assets/${isPhoneError ? 'phoneRed.svg':'phoneGray.svg'}`)" v-model="phone"/>
                    </div>        
                    <div style="height:4%;margin-top: 1%;">
                        <span class="errorText"  style="color:#F20E17;font-size:13px">{{ phoneErrorMsg }}</span>
                    </div>
                    <div class="inputHeight" style="height: 8%;">
                        <DynamicInput type="text" placeholder="Email" :isError="isEmailError" :iconImg="require(`@/assets/${isEmailError ? 'redEmail.svg':'email.svg'}`)" v-model="email"/>
                    </div>        
                    <div style="height:4%;margin-top: 1%;">
                        <span class="errorText"  style="color:#F20E17;font-size:13px">{{ emailErrorMsg }}</span>
                    </div>
                    
                    
                        <div class="inputHeight" style="height: 8%;margin: 2% 0px 1% 0px;" :style="{marginTop:emailErrorMsg == 'already exits' ?'4%':'2%'}">
                        <DynamicInput
                            :type="showPassword?'text':'password'"
                            placeholder="Password"
                            :iconImg="require(`@/assets/${isPasswordError?'redEye.svg': showPassword?'openEye.svg':'closeEye.svg'}`)"
                            v-model="password"
                            :isError="isPasswordError"
                            @password-visibility-toggled="handlePasswordVisibilityToggle" 
                            />
                    </div>
                    <div class="strongPassword" style="height:10%;">
                       <div  style="height:20%;display:flex;align-items: center;"> 
                        <div
                        class="strongPasswordLine"
                            :style="{
                            height: '12%',
                            background: weakPassword === 0 ? '#F20E17' : '#181818',
                            width: '31%',
                            }"
                        ></div>
                        <div
                        class="strongPasswordLine"
                            :style="{
                            height: '12%',
                            background: mediumPassword === 1 ? '#e2e522' : '#181818',
                            width: '31%',
                            margin: '0 2% 0 2%',
                            }"
                        ></div>
                        <div
                        class="strongPasswordLine"
                            :style="{
                            height: '12%',
                            background: strongPassword === 2 ? '#4ece31' : '#181818',
                            width: '31%',
                            }"
                        ></div>
                        </div>
                        <div style="height:4%;margin-top: 1%;"><span v-if="passwordErrorMsg" style="color:#F20E17;font-size:13px" class="errorText">{{showWarningIcon ? '⚠':''}} {{ passwordErrorMsg }}</span></div>
                        <div style="height:4%;"><span v-if="strongPassword" style="color:#828282;font-size:13px" class="errorText">Your Password  is strong. Good Job!</span></div>


                    </div>

                    <div class="iUnderstandContainer" style="height:10%;margin:1% 0 1% 0;padding-top: 2%;">
                        <div style="display:flex;">
                            <div class="form-group">
                                <input id="green-checkbox" type="checkbox" v-model="isUnderstood" class="w-4 h-4 text-green-600 border-gray-300 focus:ring-0 ring-0 appearance-none checked:bg-green-600 dark:bg-transparent dark:border-gray-600">

                                <!-- <input type="checkbox" v-model="isUnderstood" class="checkInput ring-0 focus:ring-0 focus:outline-none" />  -->
                            </div>
                             <div class="iUnderstandText" style="margin-left:10px;font-size: 13px;" :style="{color:isUnderstood ? '#0FFE50':'#828282'}">
                                I understand Market Forecaster does not provide personalised professional financial advice
                            </div>
                        </div>
                        <div class="errorText" style="color:#F20E17" v-if="requiredError">* Required fields</div>
                    </div>
                    <div style="height:8%;" class="btnStyle inputHeight">
                        <!-- @click="handleCreateAccount" -->
                        <button :class="isUnderstood ? 'btnRedHover':'btnGrayHover'"  @click="handleCreateAccount"  :style="{ background: isUnderstood ? '#0FFE50' : '#181818',color:isUnderstood?'black':'#828282',cursor: isLoading?'not-allowed':'pointer',pointerEvents: isLoading ? 'none' : 'auto'}" style="height:100%;width:100%;border-radius: 5px;font-size: 18px;display: flex;align-items: center;justify-content: center;cursor:pointer">
                            <span v-if="isLoading" style="margin-right: 10px;">
                                <Loader></Loader>
                            </span>
                            <span>
                                {{isLoading ? 'Creating an Account': 'Create an Account'}}
                            </span>
                            <div v-if="isUnderstood" style="margin-left:8%">
                                <img src="../assets/rightArrow.svg" />
                            </div>
                        </button>
                    </div>
                    <div class="iUnderstandText" style="color:#828282;margin-top:2%">
                        By creating an account, you agree to our <span style="text-decoration:underline">T&Cs</span> and have read and acknowledge the <span style="text-decoration:underline">Global Privacy Statement</span>.
                    </div>

                </div>

                <div v-else style="height:80%;">
                    <div style="height:40%;display: flex;flex-direction: column;justify-content: center;">
                    <div>
                       <span style="font-size:44px">Invalid <span style="color:#F20E17">Url</span></span>
                   </div>
                   </div>
                </div>


                <div class="hideDetail" style="height:5%;white-space: nowrap;">
                    <Footer></Footer>
                </div>
            </div>
            <!-- <div class="smallFooter" style="display: none;z-index: 0;">
                    <SmallFooter></SmallFooter>
            </div> -->
            
         
            
            <div style="height:100%;width:68%" class="hideDetail">
                <TrialInfo></TrialInfo>
            </div>
        </div> 

      
    </div>
    </template>
    
    <script>
    // import {loginUser, registerUser} from '../services/user'
 
    import DynamicInput from '../component/DynamicInput.vue'
    import Header from '../component/Header.vue'
    import Footer from '../component/Footer.vue'
    import ResendEmail from '../component/ResendEmail.vue'
    import TrialInfo from '../component/TrialInfo.vue'
    import Loader from '../component/Loader.vue'
    import {createAccount} from '../services/user'
    // import SmallFooter from '../components/SmallScreenFooter.vue'

    export default {
      name: 'SignupPage',
      components: {
            DynamicInput,
            Header,
            Footer,
            ResendEmail,
            TrialInfo,
            Loader,
            // SmallFooter
    },
      data() {
        return {
            isUnderstood: false,
            email: "",
            phone: "",
            username: "",
            password:"",
            userErrorMsg:"",
            phoneErrorMsg:"",
            emailErrorMsg:"",
            passwordErrorMsg:"",
            isEmailError:false,
            isPhoneError:false,
            isUserError:false,
            isPasswordError:false,
            weakPassword: null,
            mediumPassword:null,
            strongPassword:null,
            requiredError:false,
            showResendEmail:false,
            showPassword:false,
            showResponseError:"",
            isLoading:false,
            isResponseMessage:"",
            isResendLoading:false,
            emailRegix: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
            passwordRegix: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{10,}$/,
            authorizePage:false,
            showWarningIcon:false,
            priceId:'Hello',
            
        }
      },
      methods: {
        async handleCreateAccount(){
            console.log("create")
            if(!this.email && !this.password && !this.username && !this.phone && !this.isUnderstood){
                this.isEmailError = true
                this.emailErrorMsg = "*Required Field"
                this.isPhoneError = true
                this.phoneErrorMsg = "*Required Field"
                this.isUserError = true
                this.userErrorMsg = "*Required Field"
                this.isPasswordError = true
                this.passwordErrorMsg = "*Required Field"
                this.requiredError = true
                return
            }
            if(!this.username){
                this.isUserError = true
                this.userErrorMsg = "*Required Field"
                return
            }
            if(!this.phone){
                this.isPhoneError = true
                this.phoneErrorMsg = "*Required Field"
                return
            }

            if(!this.email){
                this.isEmailError = true
                this.emailErrorMsg = "*Required Field"
                return
            }
            if(!this.password){
                this.isPasswordError = true
                this.passwordErrorMsg = "*Required Field"
                return
            }
            if(!this.isUnderstood){
                this.requiredError = true
                return
            }

            if(this.email){
                if(!this.emailRegix.test(this.email)){
                    this.isEmailError = true
                    this.emailErrorMsg = "Invalid Email"
                    return
                }
            }
            if(this.password){
               if(!this.passwordRegix.test(this.password)){
                this.isPasswordError = true
                this.strongPassword = null
                this.showWarningIcon = true
                this.passwordErrorMsg = "The password must be more than 9 characters including uppercase,lowercase,numbers and special characters."
                return
               }
            }

            this.isLoading = true
          
            const payload = {
                email: this.email,
                password: this.password,
                firstName:this.username,
                lastName:'test',
                phone:this.phone,
                subscription:'monthly'
            }
            console.log("create account",payload)
            const res = await createAccount(payload)
            console.log("signup res",res)
            if(res?.data?.id){
                console.log("success")
                localStorage.setItem('auth', true);
                this.$router.push({ name: 'MultiChart' });
                // this.showResendEmail = true
            }else{
               
                    this.showResponseError = 'Error while creating an account. Please try again.'
                    setTimeout(() => {
                    this.showResponseError = ""    
                }, 5000);
            }
            this.isLoading = false
        },
        handlePasswordVisibilityToggle(){
            this.showPassword = !this.showPassword
        },
        // async handleResendEmail(){
        //     this.isResendLoading = true
        //     const payload = {
        //         email: this.email,
        //         password: this.password,
        //         stripeId:this.priceId
                
        //     }
        //     const res = await resendEmail(payload)
        //     if(res?.data?.success){
        //         this.isResponseMessage = res?.data?.message
        //     }else{
        //         this.isResponseMessage = res?.data?.message
        //     }
        //     setTimeout(() => {
        //             this.isResponseMessage = ""    
        //         }, 5000);
        //     this.isResendLoading = false
        // },  
        calculatePasswordStrength(pass) {
            if(pass?.length > 0){
            if (pass?.length <= 3) {
                this.weakPassword = 0
                this.mediumPassword=null,
                this.strongPassword=null
            } else if (pass?.length <= 8) {
                this.weakPassword = 0
                this.mediumPassword=1,
                this.strongPassword=null
            }else if (pass?.length > 9){
                if(this.passwordRegix.test(pass)){
                    this.weakPassword = 0
                    this.mediumPassword=1
                    this.strongPassword=2
                }
            }
        } else {
                this.weakPassword = null
                this.mediumPassword=null
                this.strongPassword=null
            }
            
         },
      },
      computed: {
    
      },
      watch: {
         password(newPassword) {
            this.calculatePasswordStrength(newPassword);
            this.isPasswordError = false
            this.passwordErrorMsg = ""
            this.showWarningIcon = false
        },
        email(){
            this.isEmailError = false
            this.emailErrorMsg = ""
        },
        username(){
            this.isUserError = false
            this.userErrorMsg = ""
        },
        phone(){
            this.isPhoneError = false
            this.phoneErrorMsg = ""
        },
        isUnderstood(){
            this.requiredError = false
        }
    },
    mounted(){
        // let authorize = this.$route.query.authorize;
        // let price_id = this.$route.query.price_id;
        
        // if(price_id == "price_1OCCGXG6YGnUUug3EU4QcoxV" || price_id == "price_1OCCGXG6YGnUUug3lbzjuNIh"){
        //     this.priceId =  price_id
        // }

      },
    }
    </script>
    
    <style>
    .join-us-container {
            /* text-align: center; */
            display: flex;
            align-items: center;
            padding-left: 20px;
        }

        .user-profile {
            width: 60px;
            height: 60px;
            border: 2px solid #fff;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
            display: inline-block;
            margin-left: -30px;
            z-index: 1;
        }

        .user-profile img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .user-profile:nth-child(2) {
            margin-left: -20px;
        }

        .user-profile:nth-child(3) {
            margin-left: -20px;
        }

        .join-us-text {
            font-size: 20px;
            color: #333333;
            margin-left: 20px;
            /* margin-top: 20px; */
        }                
    </style>