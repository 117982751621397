export const isSelected = {id: 1, name: "General", value: 'general', icon: 'generalImage', chartSettings:  [
    {id: 1, name: 'colorCandleUp', value: 'Candle Up Color', defaultValue: '#23a776', type: 'color'},
    {id: 2, name: 'colorCandleDw', value: 'Candle Down Color', defaultValue: '#e54150', type: 'color'},
    {id: 3, name: 'colorVolUp', value: 'Up Volume Colour', defaultValue: '#23a776', type: 'color'},
    {id: 4, name: 'colorVolDw', value: 'Down Volume Colour', defaultValue: '#e54150', type: 'color'},
    {id: 5, name: 'enableCrosshair', value: 'Enable CrossHair', defaultValue: true, type: 'checkbox'},
    // {id: 6, name: 'magnet', value: 'Magnet', defaultValue: false, type: 'checkbox'},
    {id: 7, name: 'candleBorder', value: 'Candle Border', defaultValue: true , type: 'checkbox'}
],}

export const allSetting = [
    {id: 1, name: "General", value: 'general', icon: 'generalImage', chartSettings: 
    [
        {id: 1, name: 'colorCandleUp', value: 'Candle Up Color', defaultValue: '#23a776', type: 'color'},
        {id: 2, name: 'colorCandleDw', value: 'Candle Down Color', defaultValue: '#e54150', type: 'color'},
        {id: 3, name: 'colorVolUp', value: 'Up Volume Colour', defaultValue: '#23a776', type: 'color'},
        {id: 4, name: 'colorVolDw', value: 'Down Volume Colour', defaultValue: '#e54150', type: 'color'},
        {id: 5, name: 'enableCrosshair', value: 'Enabled CrossHair', defaultValue: true, type: 'checkbox'},
        // {id: 6, name: 'magnet', value: 'Magnet', defaultValue: false, type: 'checkbox'},
        {id: 7, name: 'candleBorder', value: 'Candle Border', defaultValue: true , type: 'checkbox'}
    ]
,},
    {id: 2, name: "Variants", value: 'variants', icon: 'ThemeImage', chartSettings: 
    [
        {id: 8, name: 'Variant', value: 'Variant 1', defaultValue: false, type: 'radio',key:'firstVariant', variantList:{firstVariant:true,secondVariant:false,thirdVariant:false,fourthVariant:false,fifthVariant:false,sixthVariant:false}},
        {id: 9, name: 'Variant', value: 'Variant 2', defaultValue: false, type: 'radio',key:'secondVariant',variantList:{firstVariant:false,secondVariant:true,thirdVariant:false,fourthVariant:false,fifthVariant:false,sixthVariant:false}},
        {id: 10, name: 'Variant', value: 'Variant 3', defaultValue: false, type: 'radio',key:'thirdVariant',variantList:{firstVariant:false,secondVariant:false,thirdVariant:true,fourthVariant:false,fifthVariant:false,sixthVariant:false}},
        {id: 11, name: 'Variant', value: 'Variant 4', defaultValue: false, type: 'radio',key:'fourthVariant',variantList:{firstVariant:false,secondVariant:false,thirdVariant:false,fourthVariant:true,fifthVariant:false,sixthVariant:false}},
        {id: 12, name: 'Variant', value: 'Variant 5', defaultValue: false, type: 'radio',key:'fifthVariant',variantList:{firstVariant:false,secondVariant:false,thirdVariant:false,fourthVariant:false,fifthVariant:true,sixthVariant:false}},
        {id: 13, name: 'Variant', value: 'Variant 6', defaultValue: false, type: 'radio',key:'sixthVariant',variantList:{firstVariant:false,secondVariant:false,thirdVariant:false,fourthVariant:false,fifthVariant:false,sixthVariant:true}},
    ]
,}
]

export const variantColors = {
    firstVariant: {colorCandleUp:'#00B061',colorCandleDw:'#F23645',colorVolUp:'#4cc790',colorVolDw:'#fc6d72'},
    secondVariant: {colorCandleUp:'#16B195',colorCandleDw:'#F23645',colorVolUp:'#16B195',colorVolDw:'#F23645'},
    thirdVariant: {colorCandleUp:'#7D7E7E',colorCandleDw:'#e0e0e0',colorVolUp:'#7D7E7E',colorVolDw:'#D3D3D3'},
    fourthVariant: {colorCandleUp:'#0D66CE',colorCandleDw:'#F23645',colorVolUp:'#0D66CE',colorVolDw:'#F23645'},
    fifthVariant: {colorCandleUp:'#23a776',colorCandleDw:'#e54150',colorVolUp:'#23a776',colorVolDw:'#e54150'},
    sixthVariant: {colorCandleUp:'#008000',colorCandleDw:'#800000',colorVolUp:'#008000',colorVolDw:'#008000'},
}

export  const dataTypes = [
    { type: "VolumePrimary", indices: [1],color:[] },
    { type: "Stoch", indices: [1, 2],color:['#3782f2', '#f48709'] },
    { type: "CovalData", indices: [1, 2],color:['#42b28a','#5691ce'] },
    { type: "COG", indices: [1],color:['#559de0'] },
    { type: "TSI", indices: [1,2],color:['#0eb1f7','#FA67A7'] },
    { type: "WilliamsR", indices: [1],color:['#0980E8'] },
    { type: "ROC", indices: [1],color:['#279fc4'] },
    { type: "ATR", indices: [1],color:['#e52468'] },
    { type: "MACD", indices: [1,2,3],color:['#3782f2','#f48709','#9954bb'] },
    { type: "RSI", indices: [1],color:['#ec206e'] },
    { type: "CCI", indices: [1],color:['#e79e5e'] },
    { type: "ATRp", indices: [1],color:['#f44336'] },
    { type: "BBW", indices: [1],color:['#4feff2'] },
    { type: "CMO", indices: [1],color:['#559de0'] },
    { type: "SAR", indices: [1],color:['#35a9c6'] },
    { type: "KCW", indices: [1],color:['#559de0'] },
    { type: "SWMA", indices: [1],color:['#EB946C'] },
    { type: "MFI", indices: [1],color:['#91c43c'] },
    { type: "MOM", indices: [1],color:['#C0C835'] },
    { type: "SMAX", indices: [1],color:['#000cff'] },
    { type: "VWMA", indices: [1],color:['#E85EA2'] },
    { type: "EMA", indices: [1],color:['#000cff'] },
    { type: "XMA", indices: [1],color:['#e78050'] },
    { type: "DMI", indices: [1,2,3],color:['#EF1360','#4087F3','#F8B567'] },
    { type: "BB", indices: [1,2,3],color:['#72D9DB','#72D9DB','#72D9DB'] },
];


export const keyName = {
    lineWidth:'Line Width',
    color:'Color',
    period:'Period',
    lineType:'Line Type'
}