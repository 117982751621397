export const indicatorSettings = [
    {
        headingName: "Simple Moving Average (SMA)",
        settings: [
           {
            name:'',
            inputs:[
                {type: "color",defaultValue: '#FC0C0C'},
                {type: "number",defaultValue: 30},
            ]
           },
           {
            name:'',
            inputs:[
                {type: "color",defaultValue: '#110404'},
                {type: "number",defaultValue: 30},
            ]
           }
        ]
    },
    {
        headingName: "Simple Moving Average of OBV of Volume",
        settings: [
           {
            name:'',
            inputs:[
                {type: "color",defaultValue: '#9EA00E'},
                {type: "number",defaultValue: 30},
            ]
           }
        ]
    },
    {
        headingName: "Weighted Moving Average",
        settings: [
           {
            name:'Period',
            inputs:[
                {type: "color",defaultValue: '#9EA00E'},
                {type: "number",defaultValue: 30},
            ]
           }
        ]
    },
    {
        headingName: "Exponential Moving Average",
        settings: [
           {
            name:'Period',
            inputs:[
                {type: "color",defaultValue: '#1E31DB'},
                {type: "number",defaultValue: 30},
            ]
           }
        ]
    },
]
export const indicatorData =  [
    {
      headingName: "Trend Channels",
      settings: [
        { name: 'Period', inputs: [{ type: "color", defaultValue: '#FC0C0C' }, { type: "number", defaultValue: 70 }] },
      ]
    },
    {
      headingName: "Bollinger Bands",
      settings: [{ name: 'Period', inputs: [{ type: "color", defaultValue: '#FC0C0C' }, { type: "number", defaultValue: 21 }] },
      { name: 'Multiplier', inputs: [{ type: "color", defaultValue: '#110404' }, { type: "number", defaultValue: 40 }]}
    ]
    },
    {
      headingName: "Average True Range",
      settings: [
        { name: 'Period', inputs: [{ type: "color", defaultValue: '#9EA00E' }, { type: "number", defaultValue: 34 }] },
        { name: 'Multiplier', inputs: [{ type: "color", defaultValue: '#110404' }, { type: "number", defaultValue: 34 }] }
    ]
    },
    {
      headingName: "MACD",
      settings: [{ name: 'Fast Period', inputs: [{ type: "color", defaultValue: '#1E31DB' }, { type: "number", defaultValue: 34 }] },
      { name: 'Slow Period', inputs: [{ type: "color", defaultValue: '#110404' }, { type: "number", defaultValue: 34 }] },
      { name: 'Signal Period', inputs: [{ type: "color", defaultValue: '#FC0C0C' }, { type: "number", defaultValue: 34 }] }
    ],
    },
    {
      headingName: "Rate Of Change",
      settings: [
        { name: 'Period', inputs: [{ type: "color", defaultValue: '#9EA00E' }, { type: "number", defaultValue: 34 }] }
    ]
    },
    {
        headingName: "Relative Strength Index",
        settings: [
          { name: 'Period', inputs: [{ type: "color", defaultValue: '#110404' }, { type: "number", defaultValue: 34 }] }
      ]
      },
      {
        headingName: "Stochastic Oscillator",
        settings: [
          { name: 'Period', inputs: [{ type: "number", defaultValue: 15 }] },
          { name: '%K Period', inputs: [ { type: "number", defaultValue: 5 }] },
          { name: '%D Period', inputs: [{ type: "number", defaultValue: 5 }] }
      ]
      },
      {
        headingName: "Williams %R",
        settings: [
          { name: 'Period', inputs: [{ type: "color", defaultValue: '#9EA00E' }, { type: "number", defaultValue: 15 }] },
      ]
      },
      {
        headingName: "Parabolic SAR",
        settings: [
          { name: 'Start', inputs: [{ type: "color", defaultValue: '#FC0C0C' }, { type: "number", defaultValue: 15 }] },
          { name: 'Increament', inputs: [{ type: "color", defaultValue: '#9EA00E' }, { type: "number", defaultValue: 5 }] },
          { name: 'Max', inputs: [{ type: "color", defaultValue: '#110404' }, { type: "number", defaultValue: 5 }] },
          { name: 'Color', inputs: [{ type: "color", defaultValue: '#FC0C0C' }] }
      ]
      },
      {
        headingName: "VWAP",
        settings: [
          { name: 'Color', inputs: [{ type: "color", defaultValue: '#FC0C0C' }] }
      ]
      },
  ]