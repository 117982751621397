import Vue from 'vue';
import Router from 'vue-router';
import SignIn from '../pages/Signin.vue';
import SignUp from '../pages/Signup.vue';
import MultiChart from '../Multichart.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/signin',
      name: 'SignIn',
      component: SignIn
    },
    {
      path: '/signup',
      name: 'SignUp',
      component: SignUp
    },
    {
      path: '/',
      name: 'MultiChart',
      component: MultiChart,
      meta: { requiresAuth: true }
    }
  ]
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('auth');
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next({ name: 'SignIn' });
  } else {
    next();
  }
});

export default router;
