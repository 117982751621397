<template>
    <div class="w-full h-full flex flex-row">
      <div class="w-[48%]   h-fit flex gap-y-8 pb-[2rem]  flex-col">
        <div v-for="(item, i) in indicatorSettings" :key="i" class="text-[16px] font-bold ">
          <div class="dark:text-white">
            {{ item.headingName }}
          </div>
          <div v-for="(sett, ind) in item.settings" :key="ind" class="flex flex-row items-center">
            <div class="flex justify-between  w-full dark:bg-[#181818] bg-[#EFEFEF] px-2 rounded-full p-1 mt-3 items-center">
              <div class="flex items-center gap-2">
                <div v-for="(inp, index) in sett.inputs" :key="index" class="flex items-center">
                  <div v-if="inp.type === 'color'" class="w-[2.2rem] h-[2.2rem] flex rounded-full overflow-hidden">
                    <input :type="inp.type" @input="updateIndicatorSetting()" v-model="inp.defaultValue" class="w-full bg-transparent h-full cursor-pointer" />
                  </div>
                  <div v-if="inp.type === 'number'" class="w-[2.5rem] h-[1.8rem] flex overflow-hidden">
                    <input type="text" @input="updateIndicatorSetting()"   v-model.number="inp.defaultValue" class="w-full h-full  border-none text-center focus:outline-none border-[#B0B0B0] dark:bg-[#2F2F2F] shadow-lg rounded-[2px] dark:text-[#B4B4B4] text-[#3D3D3D] bg-[#FFFFFF] ">
                  </div>
                </div>
              </div>
              <div v-if="ind !== 0" @click="removeSetting(i, ind)" class="text-[14px] mr-1 cursor-pointer">
                <img :class="''" :src="require('../../src/assets/delete.svg')" alt="">
              </div>
            </div>
            <div @click="addSetting(i)" v-if="ind === item.settings.length - 1" >
              <div class="text-[14px] dark:bg-[#2F2F2F] shadow-lg ml-2 p-2 mt-3 items-center justify-center border-none rounded-[6px] bg-[#B0B0B0] cursor-pointer">
                <img :class="''" :src="require('../../src/assets/add.svg')" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-[50%] h-fit pl-4 border-l dark:border-[#181818] ml-4 flex gap-y-8 pb-[2rem] flex-col">
        <div v-for="(item, i) in indicatorData" :key="i" class="text-[16px] font-bold">
          <div class="dark:text-white">
            {{ item.headingName }}
          </div>
          <div v-for="(sett, ind) in item.settings" :key="ind" class="flex justify-start  dark:bg-[#181818] bg-[#EFEFEF] px-2 rounded-full p-1 mt-3 items-center space-x-4">
            <div class="flex items-center gap-2">
              <div v-for="(inp, index) in sett.inputs" :key="index" class="flex items-center">
                <div v-if="inp.type === 'color'" class="w-[2.2rem] h-[2.2rem] flex rounded-full overflow-hidden">
                  <input :type="inp.type" v-model="inp.defaultValue" class="w-full bg-transparent h-full cursor-pointer " :value="inp.defaultValue">
                </div>
                <div v-if="inp.type === 'number'" class="w-[2.5rem] h-[1.8rem] flex ml-2 overflow-hidden">
                  <input type="text" v-model.number="inp.defaultValue" class="w-full h-full text-center  border-none focus:outline-none border-[#B0B0B0] dark:bg-[#2F2F2F] shadow-lg rounded-[2px] dark:text-[#B4B4B4] text-[#3D3D3D] bg-[#FFFFFF] ">
                </div>
              </div>
            </div>
            <div class="text-[#3D3D3D] font-[400] text-[14px] dark:text-[#F6F6F6]">
              {{ sett?.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import {indicatorData} from "./config/index"
  export default {
    data() {
      return {
        indicatorSettings: JSON.parse(JSON.stringify(this.indicatorSettingsProp)),
        indicatorData: indicatorData,
        formattedTime: null,
      };
    },
    props: {
      indicatorSettingsProp: Array
    },
    mounted() {
      this.settingOfIndicators();
    },
    methods: {
      updateIndicatorSetting() {
       this.$emit('update-indicator-settings', this.indicatorSettings);
        
      },
      settingOfIndicators() {
      },
      addSetting(index) {
        this.indicatorSettings[index].settings.push({
          name: 'Period',
          inputs: [
            { type: "color", defaultValue: '#FC0C0C' },
            { type: "number", defaultValue: 50 }
          ]
        });
        this.updateIndicatorSetting()
      },
      removeSetting(indicatorIndex, settingIndex) {
        this.indicatorSettings[indicatorIndex].settings.splice(settingIndex, 1);
        this.updateIndicatorSetting()
     
      }
    },
    watch: {
      indicatorSettingsProp: {
      handler(newVal) {
        this.indicatorSettings = JSON.parse(JSON.stringify(newVal));
      },
      deep: true,
      immediate: true
    },
      indicatorData(val) {
        console.log("val indicatorData", val);
      },
      indicatorSettings(val){
        console.log("val indicatorSettign",val);
      }
    }
  };
  </script>
  <style scoped>
  input[type="color"] {
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      height: 100%;
      border: none;
      padding: 0;
      margin: 0;
      border-radius: 50%;
      cursor: pointer;
    }
    /* Hide default color input's inner box */
    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 0;
      border-radius: 50%;
    }
    input[type="color"]::-webkit-color-swatch {
      border: none;
      border-radius: 50%;
    }
    input[type="color"]::-moz-color-swatch {
      border: none;
      border-radius: 50%;
    }
    .color{
        width: 24px;
        height: 24px;
    }
    input[type="color"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  cursor: pointer;
}
/* Hide default color input's inner box */
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 50%;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}
input[type="color"]::-moz-color-swatch {
  border: none;
  border-radius: 50%;
}
.color {
  width: 24px;
  height: 24px;
}
/* Add focus styles for input */
input:focus {
  outline: none; /* Remove default focus outline */
  border-color: #5B9BD5; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(91, 155, 213, 0.5); /* Add a subtle shadow */
}
  </style>