<template>
    <div  style="width:100%;height:100%;overflow: hidden;padding: 40px 40px 40px 80px;" class="page-background mobileMainContainer">
        <div class="pageContainerParent"  style="width:100%;height:100%;display:flex; justify-content: space-between">
            <div class="pageContainer"  style="height:100%;width:25%;display: flex; flex-direction: column;justify-content: space-between;">
                <div style="height:8%" class="logoArea">
                    <Header></Header>
                </div>
    
                <div v-if="true" style="height:80%;">
                    <!-- form -->
                    <div style="display: flex;align-items: center;font-size: 15px;" class="errorText haveAnAcc">
                        <span style="color:white" class="text-[15px] donthave" >Don't have an account?</span>
                        <router-link :to="{name: 'SignUp'}">    
                        <span style="display:flex;align-items: center;">
                        <span style="color:#0FFE50;margin: 0px 5px 0px 5px;cursor: pointer;" class="hoverUnderline text-[15px] donthave">
                             Sign up
                        </span>
                        <img src="../assets/login.svg" />
                        </span>
                    </router-link>
                    </div>

                    <div class="formFreeTrial" style="height:15%;display: flex;flex-direction: column;justify-content: center;font-size: 40px;color:#300003;font-weight: bold;">
                        <div class="formSevenDayTrial" style="border:1px solid #F20E17;font-size: 18px;color:#F20E17;width: fit-content;border-radius: 5px;padding: 5px 15px 5px 15px;font-weight: 500;display: none;margin-bottom: 10px;">
                            14-DAYS FREE TRIAL
                        </div>
                        <span class="headerTitle text-white">
                            Sign In
                        </span>
                        <span v-if="showResponseError" class="errorText" style="color:#F20E17;font-size: 15px;margin-top: -2%;font-weight: 300;">{{ showResponseError }}</span>
                    </div>
                    <div class="inputHeight" style="height: 8%;">
                        <DynamicInput type="text" placeholder="Email" :isError="isEmailError" :iconImg="require(`@/assets/${isEmailError ? 'redEmail.svg':'email.svg'}`)" v-model="email"/>
                    </div>
                    
                    <div style="height:4%;margin-top: 1%;">
                        <span class="errorText"  style="color:#F20E17;font-size:13px">{{ emailErrorMsg }}</span>
                    </div>
                    
                    
                        <div class="inputHeight" style="height: 8%;margin: 2% 0px 1% 0px;" :style="{marginTop:emailErrorMsg == 'already exits' ?'4%':'2%'}">
                        <DynamicInput
                            :type="showPassword?'text':'password'"
                            placeholder="Password"
                            :iconImg="require(`@/assets/${isPasswordError?'redEye.svg': showPassword?'openEye.svg':'closeEye.svg'}`)"
                            v-model="password"
                            :isError="isPasswordError"
                            @password-visibility-toggled="handlePasswordVisibilityToggle" 
                            />
                    </div>
                 
                    <div style="height:8%;margin-top: 10%;" class="btnStyle inputHeight">
                        <!-- @click="handleCreateAccount" -->
                        <button :class="isUnderstood ? 'btnRedHover':'btnGrayHover'" @click="handleSignIn"  :style="{ background: isUnderstood ? '#0FFE50' : '#E0E0E0',color:isUnderstood?'black':'#828282',cursor: isLoading?'not-allowed':'pointer',pointerEvents: isLoading ? 'none' : 'auto'}" style="height:100%;width:100%;border-radius: 5px;font-size: 18px;display: flex;align-items: center;justify-content: center;cursor:pointer">
                            <span v-if="isLoading" style="margin-right: 10px;">
                                <Loader></Loader>
                            </span>
                            <span>
                                {{isLoading ? 'Signing an Account': 'Sign In'}}
                            </span>
                            <div v-if="isUnderstood" style="margin-left:8%">
                                <img src="../assets/rightArrow.svg" />
                            </div>
                        </button>
                    </div>
                

                </div>

             


                <div class="hideDetail" style="height:5%;white-space: nowrap;">
                    <Footer></Footer>
                </div>
            </div>
            <!-- <div class="smallFooter" style="display: none;z-index: 0;">
                    <SmallFooter></SmallFooter>
            </div> -->
            
         
            
            <div style="height:100%;width:68%" class="hideDetail">
                <TrialInfo></TrialInfo>
            </div>
        </div> 

      
    </div>
    </template>
    
    <script>
    // import {loginUser, registerUser} from '../services/user'
 
    import DynamicInput from '../component/DynamicInput.vue'
    import Header from '../component/Header.vue'
    import Footer from '../component/Footer.vue'
    import TrialInfo from '../component/TrialInfo.vue'
    import Loader from '../component/Loader.vue'
    import {signin} from '../services/user'
    // import SmallFooter from '../components/SmallScreenFooter.vue'

    export default {
      name: 'SigninPage',
      components: {
            DynamicInput,
            Header,
            Footer,
            TrialInfo,
            Loader,
            // SmallFooter
    },
      data() {
        return {
            isUnderstood: true,
            email: "",
            password:"",
            emailErrorMsg:"",
            passwordErrorMsg:"",
            isEmailError:false,
            isPasswordError:false,
            weakPassword: null,
            mediumPassword:null,
            strongPassword:null,
            requiredError:false,
            showResendEmail:false,
            showPassword:false,
            showResponseError:"",
            isLoading:false,
            isResponseMessage:"",
            isResendLoading:false,
            emailRegix: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
            passwordRegix: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{10,}$/,
            authorizePage:false,
            showWarningIcon:false,
            priceId:'Hello',
            
        }
      },
      methods: {
        // handleSignIn(){
        //     if(this.email == 'adnan.musil@dimensionalsys.com' && this.password == 'adnan123'){
        //         localStorage.setItem('auth', true);
        //         this.$router.push({ name: 'Chart' });
        //     }
        // },  
        async handleSignIn(){
            console.log("signin")
            if(!this.email && !this.password){
                this.isEmailError = true
                this.emailErrorMsg = "*Required Field"
                this.isPasswordError = true
                this.passwordErrorMsg = "*Required Field"
                return
            }

            if(!this.email){
                this.isEmailError = true
                this.emailErrorMsg = "*Required Field"
                return
            }
            if(!this.password){
                this.isPasswordError = true
                this.passwordErrorMsg = "*Required Field"
                return
            }
          

           
           

            this.isLoading = true
            const payload = {
                username: this.email,
                password: this.password,
            }
            console.log("singin",payload)
              
          
            const res = await signin(payload)
            console.log("signin res",res)
            if(res?.data?.accessToken){
                console.log("success")
                localStorage.setItem('auth', true);
                localStorage.setItem('token', res?.data?.accessToken);
                this.$router.push({ name: 'MultiChart' });
            }else{
              
                    this.showResponseError = 'Error while login. Please try again later.'
                    setTimeout(() => {
                    this.showResponseError = ""    
                }, 5000);
            }
            this.isLoading = false
        },
        handlePasswordVisibilityToggle(){
            this.showPassword = !this.showPassword
        },
        // async handleResendEmail(){
        //     this.isResendLoading = true
        //     const payload = {
        //         email: this.email,
        //         password: this.password,
        //         stripeId:this.priceId
                
        //     }
        //     const res = await resendEmail(payload)
        //     if(res?.data?.success){
        //         this.isResponseMessage = res?.data?.message
        //     }else{
        //         this.isResponseMessage = res?.data?.message
        //     }
        //     setTimeout(() => {
        //             this.isResponseMessage = ""    
        //         }, 5000);
        //     this.isResendLoading = false
        // },  
        calculatePasswordStrength(pass) {
            if(pass?.length > 0){
            if (pass?.length <= 3) {
                this.weakPassword = 0
                this.mediumPassword=null,
                this.strongPassword=null
            } else if (pass?.length <= 8) {
                this.weakPassword = 0
                this.mediumPassword=1,
                this.strongPassword=null
            }else if (pass?.length > 9){
                if(this.passwordRegix.test(pass)){
                    this.weakPassword = 0
                    this.mediumPassword=1
                    this.strongPassword=2
                }
            }
        } else {
                this.weakPassword = null
                this.mediumPassword=null
                this.strongPassword=null
            }
            
         },
      },
      computed: {
    
      },
      watch: {
         password(newPassword) {
            this.calculatePasswordStrength(newPassword);
            this.isPasswordError = false
            this.passwordErrorMsg = ""
            this.showWarningIcon = false
        },
        email(){
            this.isEmailError = false
            this.emailErrorMsg = ""
        },
        isUnderstood(){
            this.requiredError = false
        }
    },
    mounted(){
        // let authorize = this.$route.query.authorize;
        // let price_id = this.$route.query.price_id;
        
        // if(price_id == "price_1OCCGXG6YGnUUug3EU4QcoxV" || price_id == "price_1OCCGXG6YGnUUug3lbzjuNIh"){
        //     this.priceId =  price_id
        // }

      },
    }
    </script>
    
    <style>
    .join-us-container {
            /* text-align: center; */
            display: flex;
            align-items: center;
            padding-left: 20px;
        }

        .user-profile {
            width: 60px;
            height: 60px;
            border: 2px solid #fff;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
            display: inline-block;
            margin-left: -30px;
            z-index: 1;
        }

        .user-profile img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .user-profile:nth-child(2) {
            margin-left: -20px;
        }

        .user-profile:nth-child(3) {
            margin-left: -20px;
        }

        .join-us-text {
            font-size: 20px;
            color: #333333;
            margin-left: 20px;
            /* margin-top: 20px; */
        }                
    </style>