<template>
    <div class="w-[60rem] h-[40rem]" >
        <div class="w-full flex  items-center justify-between px-4 py-3">
            <span class="font-[500] dark:text-[#F6F6F6] text-[24px]">
                Indicators
            </span>
            <span @click="handleClose" class="cursor-pointer p-2 rounded-full hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D]">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 dark:text-white">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>

            </span>
        </div>
        <div class="w-full border-t border-b border-[#D1D1D1] px-4 py-2 items-center flex space-x-2 dark:border-[#232323]">
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-gray-500 dark:text-[#ffffff]">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                    </svg>
                </span>
                <span class="w-full" >
                    <input type="text" v-model="searchTerm" @input="searchIndicators" placeholder="Search" class="bg-transparent text-[#181818] border-none dark:text-[#BDBDBD] placeholder:text-[#6D6D6D] placeholder:dark:text-[#BDBDBD] placeholder:text-[14px] text-[16px] focus:outline-none w-full" />
                </span>
        </div>
        <div class="flex w-full h-[80%]">
            <div class="w-3/12 space-y-4 h-full py-4 pr-1">
                <div v-for="(item,i) in config" @click="handleIndicatorSelect(item)" :key="i" :class="selectedConfig?.name==item.name ? 'bg-[#F0F3FA] dark:bg-[#2D2D2D] cursor-default':'cursor-pointer'" class="flex px-4 space-x-4 py-1.5 hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D]">
                    <div>
                        <img :src="require(`../assets/chart/${item.image}`)" />
                    </div>
                    <div class="text-[14px] dark:text-[#F6F6F6]">
                        {{ item.name }}
                    </div>
                </div>
            </div>
            <div v-if="filteredIndicators?.length > 0" class="w-9/12 py-4 h-full overflow-y-auto border-l border-[#D1D1D1] dark:border-[#232323] no-scrollbar">
                <div @click="handleIndicators(indicator)" v-for="(indicator,ind) in filteredIndicators" :key="ind" class="w-full px-4 cursor-pointer py-2.5 flex items-center justify-between hover:bg-[#F0F3FA] dark:hover:bg-[#2D2D2D]">
                    <span class="text-[14px] dark:text-[#BDBDBD]">
                    {{ indicator?.desc }}
                    </span>
                    <span v-if="getTick(indicator)">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-green-700">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                        </svg>
                    </span>
                </div>
             
            </div>
            <div v-else class="w-9/12 py-4 h-full flex items-center overflow-y-auto justify-center text-[#6D6D6D] text-[18px] border-l border-[#D1D1D1] dark:border-[#232323] no-scrollbar">
                No indicator found
            </div>
        </div>
    </div>
</template>

<script>
import {trendConfig} from '../utils/chartEvent'
import { mapActions, mapGetters } from 'vuex';


export default {
    name: 'IndicatorModal',
    data() {
        return {
            config: trendConfig,
            selectedConfig:trendConfig[0],
            searchTerm:'',
            filteredIndicators:[]
        }
    },
    computed: {
    ...mapGetters(['getSelectedIndicators']),
  },
  created() {
    this.filteredIndicators = this.selectedConfig?.list
  },

    methods: {
        ...mapActions(['toggleIndicator']),
        handleIndicatorSelect(ind){
            this.selectedConfig = ind
            this.filteredIndicators = this.selectedConfig?.list
        },
        getTick(val){
            let found = this.getSelectedIndicators?.some(indicator => indicator?.name == val?.name)
            if(found){
                return true
            }else{
                return false
            }
        },
        handleIndicators(indicator) {
           this.$emit("addingIndicator",indicator)
           this.toggleIndicator(indicator);
        },
        handleClose() {
            this.$emit('close')
        },
        getAllIndicators() {
            return this.config?.flatMap(category => category.list);
            },

        searchIndicators() {
                const searchTermLower = this.searchTerm?.toLowerCase();
                if(searchTermLower){
                    this.filteredIndicators = this.getAllIndicators()?.filter(indicator =>
                    indicator.desc.toLowerCase()?.includes(searchTermLower)
                );
            }else{
                this.filteredIndicators = this.selectedConfig.list;
            }
             },
    },
   
}

</script>

<style>


</style> 